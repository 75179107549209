.home {
  .hero-buttons {
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  .portfolio-button {
    background: #fd7e14;
    color: #fff;
  }
}
